<template>
<div class="PredictionDL">
   <h3 class="RTitle">1 {{ $t('AutoDL.DL.PredictionDL.eqxszp') }}</h3>
   <span class="articleTitle">
         1.1 {{ $t('AutoDL.DL.PredictionDL.ci6p78') }}
      </span>
   <div id="EChart" />
   <span class="articleTitle">
         1.2 {{ $t('AutoDL.DL.PredictionDL.v2sxs4') }}
      </span>
   <el-table
      :data="valueData"
      border
      style="width: 100%">
      <el-table-column prop="key" label="$t('AutoDL.DL.PredictionDL.w7ihl1')">
      </el-table-column>
      <el-table-column prop="value" label="$t('AutoDL.DL.PredictionDL.b4sml4')">
      </el-table-column>
   </el-table>
   <section>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonTop')"
         placement="top-start"
         :hide-after="200">
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-left"
            :disabled="lastVisible"
            circle
            @click="routerPush('TrainingDL')" />
      </el-tooltip>
      <el-tooltip
         class="item"
         effect="light"
         :content="$t('pretreatment.buttonNext')"
         placement="top-start">
         <el-button
            class="Step"
            type="primary"
            icon="el-icon-arrow-right"
            :disabled="nextVisible"
            circle
            @click="routerPush('Preview')" />
      </el-tooltip>
   </section>
</div>
</template>

<script>
import {
   mapState
} from "vuex";
export default {
   computed: {
      ...mapState([
         "task_id_DL",
         "dataset_id_DL",
      ]),
   },
   data() {
      return {
         EChart: '',
         EChartConfig: '',
         valueData: []
      };
   },
   mounted() {
      this.EchartFn()
   },
   methods: {
      routerPush(name) {
         this.$router.push({
            name: name,
         });
      },
      toJSON(str) {
         try {
            return JSON.parse(str)
         } catch (err) {
            return str
         }
      },
      EchartDataFn(obj) {
         let data = []
         for (let k in obj) {
            let v = {
               name: k,
               data: [],
               type: 'line'
            }
            for (let k2 in obj[k].observations) {
               v.data.push(obj[k].observations[k2].value[0])
            }
            data.push(v)
         }
         return data
      },
      valuesFn(obj) {
         for (let k in obj) {
            let data = {
               value: '',
               key: ''
            }
            data.value = obj[k]
            data.key = k
            this.valueData.push(data)
         }
         console.log(this.valueData)
      },
      EchartFn() {
         let output = ''
         let series = ''
         let xAxis_data = []
         let legend_data = []
         this.axios(`${window.apis.AUTODL_TASK}${this.task_id_DL}/`).then(res => {
            output = this.toJSON(res.data.train_output)
            this.valuesFn(output.values)
            series = this.EchartDataFn(output.metrics)
            legend_data = Object.keys(output.metrics)
            for (let i = 0; i < series[0].data.length; i++) {
               xAxis_data.push(`Step${i}`)
            }

            this.EChart = this.$echarts.init(document.getElementById("EChart"));
            this.EChartConfig = {
               // title: {
               //    text: '折线图堆叠'
               // },
               tooltip: {
                  trigger: 'axis'
               },
               legend: {
                  data: legend_data
               },
               grid: {
                  left: '3%',
                  right: '4%',
                  bottom: '3%',
                  containLabel: true
               },
               toolbox: {
                  feature: {
                     saveAsImage: {}
                  }
               },
               xAxis: {
                  type: 'category',
                  boundaryGap: false,
                  data: xAxis_data
               },
               yAxis: {
                  type: 'value'
               },
               series: series
            }
            window.onresize = function () {
               this.EChart.resize();
            };
            // 设置参数
            this.EChart.setOption(this.EChartConfig, true);
         })
      }
   }
};
</script>

<style lang="scss" scoped>
.PredictionDL {
   margin-top: 30px;

   .RTitle {
      width: 100%;
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      overflow: hidden;
      display: block;
      margin: 20px 0;
      text-align: left;
   }
   .articleTitle {
   display: block;
   width: 100%;
   height: 30px;
   margin-top: 30px;
   font-size: 16px;
   font-weight: bold;
   color: #4e4f54;
   text-align: left;
   margin: 10px 0;
}
   #EChart {
      height: 400px;
   }

   section {
      position: fixed;
      right: 100px;
      bottom: 50px;
      z-index: 9999;

      .Step {
         margin: 0 15px;
         box-shadow: 0px 0px 7px #333333;
      }
   }
}
</style>
